import {gsap} from "gsap";
import { qsArray } from "../utilities";

class PhoneCarousel {
  constructor(params) {
    this.carouselElement = document.querySelector(params.selector);
    this.images = qsArray('.screenshot', this.carouselElement);
    this.index = 0;
    this.fadeTime = 0.5;

    gsap.set(this.images, {opacity: 0});
    this.selectedImage = this.carouselElement.querySelector('#first-screenshot');
    gsap.set(this.selectedImage, {opacity: 1});

    let intervalId = window.setInterval(this.nextImage.bind(this), 3000);
  }

  nextImage () {
    // console.log('PhoneCarousel.nextImage ' + this.index);
    gsap.to(this.selectedImage, {
      opacity: 0,
      ease: 'sine.in',
      duration: this.fadeTime
    });
    this.index++;
    if (this.index >= this.images.length) {
      this.index = 0;
    }
    this.selectedImage = this.images[this.index];
    gsap.to(this.selectedImage, {
      opacity: 1,
      ease: 'sine.out',
      duration: this.fadeTime
    });
  }
}

export default PhoneCarousel;
