import {gsap} from "gsap";
import { observeVisible } from "../utilities";

class CharacterCarousel {
  constructor(params) {
    // console.log('CharacterCarousel()');
    this.selector = params.selector;

    this.carouselElement = document.querySelector(this.selector);
    this.allCharacterBubbles = this.carouselElement.querySelectorAll('.character-bubble');
    this.characterBubbleQueue = [];
    this.resetCharacterBubbleQueue();

    // set initial position across the entire path
    let bubble;
    this.duration = 40;
    let self = this;
    // let delay = 2.75;
    for (let i = 1; i < 10; i++) {
      if (this.characterBubbleQueue.length === 0) {
        this.resetCharacterBubbleQueue();
      }

      let startPosition = 1 - (0.125 * i);
      if ( startPosition < 0 ) {
        break;
      } else {
        bubble = this.characterBubbleQueue.shift();
        // console.log('bubble ' + i + ' is ' + bubble);
        // console.log('now character queue is ', this.characterBubbleQueue);
        let adjustedDuration = this.duration * (1 - startPosition);
        gsap.to(bubble, {
          duration: adjustedDuration,
          ease: 'none',
          onCompleteParams: [this, bubble],
          onComplete: this.pathComplete,
          motionPath:{
            path: "#carousel-path",
            align: "#carousel-path",
            start: startPosition,
            alignOrigin: [0.5, 0.5]
          }
        });
        gsap.to(bubble, {
          duration: 0.5,
          scale: 1,
          opacity: 1,
        });
      }
    }

  }

  pathComplete (self, oldBubble) {
    // console.log('done with bubble ', oldBubble);
    gsap.to(oldBubble, {
      duration: 0.5,
      scale: 0,
      opacity: 0
    });

    self.addNextCharacterBubble();
  }

  resetCharacterBubbleQueue () {
    this.characterBubbleQueue = [...this.allCharacterBubbles];
  }

  addNextCharacterBubble () {
    let self = this;
    if (this.characterBubbleQueue.length === 0) {
      this.resetCharacterBubbleQueue();
    }
    let bubble = this.characterBubbleQueue.shift();
    // console.log('addNextCharacterBubble is ' + bubble);
    // console.log('duration is ' + this.duration);

    gsap.to(bubble, {
      duration: this.duration,
      ease: 'none',
      onCompleteParams: [this, bubble],
      onComplete: this.pathComplete,
      motionPath:{
        path: "#carousel-path",
        align: "#carousel-path",
        alignOrigin: [0.5, 0.5]
      }
    });
    gsap.to(bubble, {
      duration: 0.5,
      scale: 1,
      opacity: 1,
    });
  }


  /**
   * Not using these yet
   */
  // // OK TO OVERRIDE
  // setInitialProperties () {
  //   // console.log('CharacterCarousel.setInitialProperties()');
  //   gsap.set(this.selector, {opacity: 0, x: -200});
  // }
  //
  // // OK TO OVERRIDE
  // setAmbientAnimation () {
  //   // console.log('CharacterCarousel.setAmbientAnimation()');
  //   this.ambient = gsap.timeline({
  //     repeat: -1,
  //     repeatDelay: 2 + (Math.random() * 2)
  //   })
  //   this.ambient.to(this.selector, {
  //     y: -25,
  //     rotation: 12,
  //     duration: 0.4,
  //     // delay: 0.4,
  //     yoyo: true,
  //     repeat: 1
  //   });
  //   this.ambient.to(this.selector, {
  //     y: -25,
  //     rotation: -9,
  //     duration: 0.4,
  //     yoyo: true,
  //     repeat: 1
  //   });
  //   this.ambient.pause();
  // }
  //
  // // OK TO OVERRIDE
  // setEnterAnimation() {
  //   // console.log('CharacterCarousel.setEnterAnimation()');
  //   let self = this;
  //   this.enter = gsap.to(this.selector, {
  //     x: 0,
  //     opacity: 1,
  //     duration: .4,
  //     onComplete: function () {
  //       self.ambient.restart();
  //     }
  //   });
  //   this.enter.pause();
  // }
  //
  // // OK TO OVERRIDE
  // setExitAnimation() {
  //   // console.log('CharacterCarousel.setExitAnimation()');
  //   let self = this;
  //   this.exit = gsap.to(this.selector, {
  //     x: -200,
  //     opacity: 0,
  //     duration: .5,
  //     onComplete: function () {
  //       self.ambient.restart();
  //       self.ambient.pause();
  //     }
  //   });
  //   this.exit.pause();
  // }
  //
  // observeIntersections (isVisible) {
  //   // console.log('CharacterCarousel.observeIntersections()');
  //   // console.log('Bouncy Character visible: ' + this.visible + ' === ' + isVisible);
  //   if (this.visible === false && isVisible === true) {
  //     // console.log('CharacterCarousel show');
  //     this.enter.restart();
  //     this.visible = true;
  //   } else if (this.visible === true && isVisible === false) {
  //     // console.log('CharacterCarousel hide');
  //     this.exit.restart();
  //     this.visible = false;
  //   }
  // }
}

export default CharacterCarousel;
