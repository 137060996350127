import components from '../utilities/components';
import { qsArray } from '../utilities';

// TODO: Make this dynamic!!!

let VideoPlayer = function () {
  let vp = this;

  if (VideoPlayer.prototype._singletonInstance) {
    return VideoPlayer.prototype._singletonInstance;
  }
  VideoPlayer.prototype._singletonInstance = this;

  vp.triggers = qsArray('.yt-trigger');
  // console.log(vp.triggers);

  vp.$component = $('#video-player-component');
  vp.$close = vp.$component.find('.button-close');

  vp.$close.on('click', vp.close.bind(vp));

  $('.yt-trigger').on('click', function () {
    let ytId = $(this).attr('data-yt');
    // console.log('clicked open video', ytId);
    vp.activeVideoId = ytId;
    vp.open();
  });

};

VideoPlayer.prototype.initialize = function () {

  let vp = this;

  // let tag = document.createElement('script');
  //
  // tag.src = "https://www.youtube.com/iframe_api";
  // let firstScriptTag = document.getElementsByTagName('script')[0];
  // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  // window.onYouTubeIframeAPIReady = () => {
  //   this.player = new YT.Player('yt-node', {
  //     height: '100%',
  //     width: '100%',
  //     videoId: this.$component.attr('data-yt'),
  //     playerVars: {
  //       rel: 0,
  //       autoplay: 0,
  //       autohide: 2,
  //       controls: 2,
  //       modestbranding: 1,
  //       showinfo: 1,
  //       iv_load_policy: 3
  //     },
  //     events: {
  //       onReady: () => {
  //         // console.log('VideoPlayer initalized!');
  //         if (this.isOpen) {
  //           this.player.playVideo();
  //         }
  //       }
  //     }
  //   });
  // };

  if (vp.triggers.length) {
    vp.player = new YT.Player('yt-node', {
      height: '100%',
      width: '100%',
      videoId: vp.activeVideoId,
      playerVars: {
        rel: 0,
        autoplay: 0,
        autohide: 2,
        controls: 2,
        modestbranding: 1,
        showinfo: 1,
        iv_load_policy: 3
      },
      events: {
        onReady: () => {
          console.log('VideoPlayer initalized!');
          if (vp.isOpen) {
            vp.player.playVideo();
          }
        }
      }
    });
  }
};

VideoPlayer.prototype.open = function () {
  let vp = this;
  components.open(vp.$component);
  vp.isOpen = true;
  if (!vp.player) {
    vp.initialize();
  } else if (vp.player && vp.player.loadVideoById) {
    vp.player.loadVideoById(vp.activeVideoId);
  } else {
    console.warn('No action available on youtube component open')
  }
};

VideoPlayer.prototype.close = function () {
  let vp = this;
  vp.isOpen = false;
  if (vp.player && vp.player.stopVideo) {
    vp.player.stopVideo();
  } else {
    console.warn('No action available on youtube component close');
  }
  components.close(vp.$component);
};

export default VideoPlayer;
