/**
 * Component Helpers
 */

let $window = $(window);
let $body = $(document.body);
let top = 0;

function open( $c ) {
  if(!$body.hasClass('is-scroll-lock')){
    top = $window.scrollTop();
    $body.css('top', top * -1);
    $body.addClass('is-scroll-lock');
  }
  $c.addClass('is-active');
  $c[0].clientHeight;
  $c.addClass('is-visible');
}

function close( $c, soft ) {
  if(soft === undefined || soft === false) {
    $body.removeClass('is-scroll-lock');
    $body.css('top', 0);
    $window.scrollTop(top);
  }
  $c.removeClass('is-active');
  $c.removeClass('is-visible');
}

export default {
  open, close
}
