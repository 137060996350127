import {gsap} from "gsap";
import { observeVisible } from "../utilities";

class AnimatedElement {
  constructor(params) {
    // console.log('AnimatedElement()');
    this.visible = false;
    this.selector = params.selector;
    this.marker = params.marker;
    this.direction = params.direction;
    this.setInitialProperties();
    this.setAmbientAnimation();
    this.setEnterAnimation();
    this.setExitAnimation();

    observeVisible(params.marker, this.observeIntersections, this);

  }

  // OK TO OVERRIDE
  setInitialProperties () {
    // console.log('AnimatedElement.setInitialProperties()');
    gsap.set(this.selector, {opacity: 0, x: -200});
  }

  // OK TO OVERRIDE
  setAmbientAnimation () {
    // console.log('AnimatedElement.setAmbientAnimation()');
    this.ambient = gsap.timeline({
      repeat: -1,
      repeatDelay: 2 + (Math.random() * 2)
    })
    this.ambient.to(this.selector, {
      y: -25,
      rotation: 12,
      duration: 0.4,
      // delay: 0.4,
      yoyo: true,
      repeat: 1
    });
    this.ambient.to(this.selector, {
      y: -25,
      rotation: -9,
      duration: 0.4,
      yoyo: true,
      repeat: 1
    });
    this.ambient.pause();
  }

  // OK TO OVERRIDE
  setEnterAnimation() {
    // console.log('AnimatedElement.setEnterAnimation()');
    let self = this;
    this.enter = gsap.to(this.selector, {
      x: 0,
      opacity: 1,
      duration: .4,
      onComplete: function () {
        self.ambient.restart();
      }
    });
    this.enter.pause();
  }

  // OK TO OVERRIDE
  setExitAnimation() {
    // console.log('AnimatedElement.setExitAnimation()');
    let self = this;
    this.exit = gsap.to(this.selector, {
      x: -200,
      opacity: 0,
      duration: .5,
      onComplete: function () {
        self.ambient.restart();
        self.ambient.pause();
      }
    });
    this.exit.pause();
  }

  observeIntersections (isVisible) {
    // console.log('AnimatedElement.observeIntersections()');
    // console.log('Bouncy Character visible: ' + this.visible + ' === ' + isVisible);
    if (this.visible === false && isVisible === true) {
      // console.log('AnimatedElement show');
      // this.enter.restart();
      this.visible = true;
      this.onScreen();
    } else if (this.visible === true && isVisible === false) {
      // console.log('AnimatedElement hide');
      // this.exit.restart();
      this.visible = false;
      this.offScreen();
    }
  }

  onScreen () {
    // console.log('AnimatedElement show');
    this.exit.restart();
    this.exit.pause();
    this.ambient.restart();
    this.ambient.pause();
    this.enter.restart(true);
    // this.visible = true;
  }

  offScreen () {
    // console.log('AnimatedElement hide');
    this.enter.restart();
    this.enter.pause();
    this.ambient.restart();
    this.ambient.pause();
    this.exit.restart();
    // this.visible = false;
  }
}

export default AnimatedElement;
