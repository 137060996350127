import {gsap} from "gsap";
import AnimatedElement from "./animated-element";

class MusicNote extends AnimatedElement{
  constructor(params) {
    // console.log('MusicNote() ', params);
    super(params);
  }

  // OK TO OVERRIDE
  setInitialProperties () {
    // console.log('MusicNote.setInitialProperties() ' + this.direction);
    gsap.set(this.selector, {opacity: 0, scale: 0, y: 50, x: (200 * this.direction)});
  }

  // OK TO OVERRIDE
  setAmbientAnimation () {
    // console.log('MusicNote.setAmbientAnimation()');
    let startRotation = -10 + (Math.random() * 22);
    gsap.set(this.selector, {rotation: startRotation});
    this.ambient = gsap.timeline({
      repeat: -1,
      repeatDelay: 1 + Math.random() * 1
    });
    this.ambient.to(this.selector, {
      rotation: -10,
      duration: 0.2
    });
    this.ambient.to(this.selector, {
      rotation: 8,
      duration: 0.2
    });
    this.ambient.to(this.selector, {
      rotation: -6,
      duration: 0.2
    });
    this.ambient.to(this.selector, {
      rotation: 8,
      duration: 0.2
    });
    this.ambient.to(this.selector, {
      rotation: -6,
      duration: 0.2
    });
    this.ambient.to(this.selector, {
      rotation: startRotation,
      duration: 0.2
    });
    this.ambient.pause();
  }

  // OK TO OVERRIDE
  setEnterAnimation() {
    // console.log('MusicNote.setEnterAnimation()');
    let self = this;
    this.enter = gsap.fromTo(this.selector, {opacity: 0, scale: 0, y: 50, x: (200 * this.direction)},
    {
      x: 0,
      y: 0,
      scale: 1,
      opacity: 1,
      duration: .4,
      delay: (1 * Math.random()),
      onComplete: function () {
        self.ambient.restart();
      }
    });
    this.enter.pause();
  }

  // OK TO OVERRIDE
  setExitAnimation() {
    // console.log('MusicNote.setExitAnimation()');
    let self = this;
    this.exit = gsap.to(this.selector, {
      opacity: 0,
      duration: .5,
      onComplete: function () {
        self.ambient.restart();
        self.ambient.pause();
      }
    });
    this.exit.pause();
  }

}

export default MusicNote;