/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @param {Function} func to be executed on debounce
 * @param {number} wait time in milliseconds
 * @param {boolean} immediate boolean
 *
 * */
export function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    let context = this, args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function qsArray ( selector, node ) {
  node = node || document;
  let nodeList = node.querySelectorAll(selector);
  let array = [];
  for(let i = 0; i < nodeList.length; i++) {
    array.push(nodeList[i]);
  }
  return array;
}

export function getWidth() {
  return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
  );
}

export function observeVisible ( selector, callback, callbackScope ) {
  // console.log('observeVisible on ' + selector);
  let triggers = qsArray(selector);

  if (triggers.length === 0) {
    return;
  }

  let options = {
    root: null,
    rootMargin: "0px",
    // threshold: [0, 0.25, 0.5, 0.75, 1]
    threshold: [0.25, 0.75]
  };
  let contentObserver = new IntersectionObserver(handleContentIntersect, options);

  triggers.forEach(function(trigger){
    contentObserver.observe(trigger);
  });

  function handleContentIntersect (entries) {
    // console.log('handleContentIntersect');
    entries.forEach(entry => {
      let el = entry.target;
      // console.log('ratio is ' + entry.intersectionRatio);
      if (entry.intersectionRatio >= 0.74) {
        callback.call(callbackScope, true);
        // console.log('visible');
      } else if (entry.intersectionRatio <= 0.26 && entry.boundingClientRect.top > 0) {
        callback.call(callbackScope, false);
        // console.log('not visible');
      }
    });
  }

}
