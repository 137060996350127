import {gsap} from "gsap";

class BubbleDivider {
  constructor(params) {

    // TODO: make cirlce tweens in greensock instead of css to get rid of the startup delay

    // let hero = new Hero();
    // let heroBubbles = document.getElementById(params.selector);
    let heroBubbles = document.querySelector(params.selector);
    let bubbleCount = 40;
    let bubble;
    let size;
    let xPosition;
    let yPosition;
    let delayCounter = 0;
    for (let i = 0; i < bubbleCount; i++) {
      bubble = document.createElement('div');
      bubble.className = 'bubble';
      size = Math.round( 130 + (Math.random() * 70));
      // xPosition = -5 + (Math.random() * 110);
      xPosition = -10 + ((120 / bubbleCount ) * i) + (-2 + Math.random() * 4);
      yPosition = -5 + (Math.random() * 110);
      bubble.style.top = (-50 + yPosition) + '%';
      bubble.style.left = xPosition + '%';
      bubble.style.width = size + 'px';
      bubble.style.height = size + 'px';

      let duration = 10 + (Math.random() * 10);
      let r = 20 + (Math.random() * 40);
      let circleTween = gsap.to(bubble, {
        motionPath: {
          // path: `M ${-r}, 0
          //  a ${r},${r} 0 1,0 ${r * 2},0
          //  a ${r},${r} 0 1,0 -${r * 2},0z`
          // path: 'M-40,0a40,20 0 1,0 80,0a40,20 0 1,0 -80,0'
          path: `M${-r},0a${r},${r/3} 0 1,0 ${r*2},0a${r},${r/3} 0 1,0 ${r*-2},0`
        },
        duration: duration,
        yoyo: true,
        // ease: "none"
        ease: 'sine.inOut',
        repeat: -1
      });
      circleTween.progress(Math.random());
      circleTween.play();

      heroBubbles.appendChild(bubble);
    }

  }
}

export default BubbleDivider;
