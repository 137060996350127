import { qsArray } from "../utilities";

class IsVisible {
  constructor() {
    // console.log('new IsVisible()');
    let triggers = qsArray('.watch-trigger');

    if (triggers.length === 0) {
      return;
    }

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0, 0.25, 0.5, 0.75, 1]
    };
    let contentObserver = new IntersectionObserver(handleContentIntersect, options);

    triggers.forEach(function(trigger){
      contentObserver.observe(trigger);
    });

    function handleContentIntersect (entries) {
      // console.log('handleContentIntersect');
      entries.forEach(entry => {
        let el = entry.target;
        // console.log('ratio is ' + entry.intersectionRatio);
        if (entry.intersectionRatio >= 0.2 && !el.classList.contains('is-visible')) {
          el.classList.add('is-visible');
          // console.log('visible');
        } else if (entry.intersectionRatio <= 0.05 && entry.boundingClientRect.top > 0 && el.classList.contains('is-visible')) {
          el.classList.remove('is-visible');
          // console.log('not visible');
        }
      });
    }

  }
}

export default IsVisible;
