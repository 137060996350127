import {gsap} from "gsap";
import AnimatedElement from "./animated-element";
import { getWidth } from "../utilities";

class ButtonBar extends AnimatedElement{
  constructor(params) {
    // console.log('ButtonBar()');
    super(params);
  }

  // OK TO OVERRIDE
  setInitialProperties () {
    // console.log('ButtonBar.setInitialProperties()');
    this.buttonBar = document.querySelector(this.selector);
    this.brushLeft = this.buttonBar.querySelector('.toothbrush-left');
    this.leftBubbleBorder1 = this.buttonBar.querySelector('.left-bubble-border-1');
    this.leftBubbleBorder2 = this.buttonBar.querySelector('.left-bubble-border-2');
    this.leftBubbleBorder3 = this.buttonBar.querySelector('.left-bubble-border-3');
    this.leftBubbleBorder4 = this.buttonBar.querySelector('.left-bubble-border-4');
    this.leftBubbleFill1 = this.buttonBar.querySelector('.left-bubble-fill-1');
    this.leftBubbleFill2 = this.buttonBar.querySelector('.left-bubble-fill-2');
    this.leftBubbleFill3 = this.buttonBar.querySelector('.left-bubble-fill-3');
    this.leftBubbleFill4 = this.buttonBar.querySelector('.left-bubble-fill-4');
    this.leftIsSudsing = false;

    this.brushRight = this.buttonBar.querySelector('.toothbrush-right');
    this.rightBubbleBorder1 = this.buttonBar.querySelector('.right-bubble-border-1');
    this.rightBubbleBorder2 = this.buttonBar.querySelector('.right-bubble-border-2');
    this.rightBubbleBorder3 = this.buttonBar.querySelector('.right-bubble-border-3');
    this.rightBubbleBorder4 = this.buttonBar.querySelector('.right-bubble-border-4');
    this.rightBubbleFill1 = this.buttonBar.querySelector('.right-bubble-fill-1');
    this.rightBubbleFill2 = this.buttonBar.querySelector('.right-bubble-fill-2');
    this.rightBubbleFill3 = this.buttonBar.querySelector('.right-bubble-fill-3');
    this.rightBubbleFill4 = this.buttonBar.querySelector('.right-bubble-fill-4');
    this.rightIsSudsing = false;

    gsap.set(this.brushLeft, {opacity: 0, x: -200, rotate: 45});
    gsap.set(this.brushRight, {opacity: 0, x: 200, rotate: -45});
  }

  // OK TO OVERRIDE
  setAmbientAnimation () {
    // console.log('ButtonBar.setAmbientAnimation()');
    let self = this;
    this.ambient = gsap.timeline({
      repeat: -1,
      repeatDelay: 2
    });

    //left brush
    this.ambient.to(this.brushLeft, {
      x: 0,
      duration: 0.4,
      ease: 'sine.out'
    });
    this.ambient.to(this.brushLeft, {
      x: 35,
      y: -35,
      duration: 0.4,
      // delay: 0.4,
      ease: 'none',
      yoyo: true,
      repeat: 5,
      onStart: function () {
        self.startLeftSuds();
      },
    });
    this.ambient.to(this.brushLeft, {
      x: -50,
      duration: 0.4,
      // delay: 0.4,
      ease: 'sine.out'
    });

    //right brush
    this.ambient.to(this.brushRight, {
      x: 0,
      duration: 0.4,
      ease: 'sine.out'
    }, 0.5);
    this.ambient.to(this.brushRight, {
      x: -35,
      y: -35,
      duration: 0.4,
      // delay: 0.4,
      ease: 'none',
      yoyo: true,
      repeat: 5,
      onStart: function () {
        self.startRightSuds();
      },
    }, ">");
    this.ambient.to(this.brushRight, {
      x: 50,
      duration: 0.4,
      // delay: 0.4,
      ease: 'sine.out'
    }, ">");
    this.ambient.pause();
  }

  startLeftSuds () {
    // console.log('start left suds ' + this.leftIsSudsing);
    if (this.leftIsSudsing === false) {
      this.growBubble(this.leftBubbleBorder1, this.leftBubbleFill1, 0);
      this.growBubble(this.leftBubbleBorder2, this.leftBubbleFill2, 0.1);
      this.growBubble(this.leftBubbleBorder3, this.leftBubbleFill3, 0.2);
      this.growBubble(this.leftBubbleBorder4, this.leftBubbleFill4, 0.3);
      this.leftIsSudsing = true;
    }
  }

  startRightSuds () {
    // console.log('start right suds ' + this.rightIsSudsing);
    if (this.rightIsSudsing === false) {
      this.growBubble(this.rightBubbleBorder1, this.rightBubbleFill1, 0);
      this.growBubble(this.rightBubbleBorder2, this.rightBubbleFill2, 0.1);
      this.growBubble(this.rightBubbleBorder3, this.rightBubbleFill3, 0.2);
      this.growBubble(this.rightBubbleBorder4, this.rightBubbleFill4, 0.3);
      this.rightIsSudsing = true;
    }
  }

  growBubble (border, fill, delay) {
    let self = this;
    gsap.set([border], {opacity: 0, width: 9, height: 9});
    gsap.set([fill], {opacity: 0, width: 1, height: 1});
    let size = 40 + Math.round(Math.random() * 20);
    let outline = 8;
    if (getWidth() < 1024) {
      size = 30 + Math.round(Math.random() * 15);
      outline = 6;
    }
    if (getWidth() < 500) {
      // console.log('smallest bubbles');
      size = 20 + Math.round(Math.random() * 10);
      outline = 4;
    }
    this.suds = gsap.timeline({
    });
    this.suds.to(border, {
      width: size,
      height: size,
      duration: 2,
      delay: delay
    });
    this.suds.to([border, fill], {
      opacity: 1,
      duration: 0.2
    }, "<");
    this.suds.to(fill, {
      width: size - outline,
      height: size - outline,
      duration: 2.2
    }, "<");
    this.suds.to(border, {
      width: size + 5,
      height: size + 5,
      opacity: 0,
      duration: 0.2,
      delay: 0.6
    }, ">");
    this.suds.to(fill, {
      width: size + 5,
      height: size + 5,
      opacity: 0,
      // ease: 'sine.in',
      duration: 0.2,
      onComplete: function () {
        self.leftIsSudsing = false;
        self.rightIsSudsing = false;
      }
    }, "<");
  }

  // OK TO OVERRIDE
  setEnterAnimation() {
    // console.log('ButtonBar.setEnterAnimation()');
    let self = this;
    this.enter = gsap.timeline({
    });
    this.enter.to([this.brushLeft], {
      x: -50,
      opacity: 1,
      duration: .4,
      onComplete: function () {
        self.ambient.restart();
      }
    });
    this.enter.to([this.brushRight], {
      x: 50,
      opacity: 1,
      duration: .4
    }, "<");
    this.enter.pause();
  }

  // OK TO OVERRIDE
  setExitAnimation() {
    // console.log('ButtonBar.setExitAnimation()');
    let self = this;
    // this.exit = gsap.to(this.brushLeft, {
    //   x: -200,
    //   opacity: 0,
    //   duration: .5,
    //   onComplete: function () {
    //     self.ambient.restart();
    //     self.ambient.pause();
    //   }
    // });
    // this.exit.pause();

    this.exit = gsap.timeline({
    });
    this.exit.to([this.brushLeft], {
      x: -200,
      opacity: 0,
      duration: .4,
      onComplete: function () {
        self.ambient.restart();
        self.ambient.pause();
      }
    });
    this.exit.to([this.brushRight], {
      x: 200,
      opacity: 0,
      duration: .4
    }, "<");
    this.exit.pause();
  }

}

export default ButtonBar;
