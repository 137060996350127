import components from '../utilities/components';

let SUPPORTED_LOCALES = [
  'de-de',
  'en-au',
  'en-ca',
  'en-gb',
  'en-us',
  'es-es',
  'es-la',
  'fr-be',
  'fr-ca',
  'it-it',
  'nl-be',
  'nl-nl',
  'pt-pt',
  'fr-fr'
];
let LOCALE_KEY = 'rumble-rush-locale-key';
let OPEN_LOCALE_SELECTOR = 'OPEN_LOCALE_SELECTOR';

let LocaleSelector = function () {
  if (LocaleSelector.prototype._singletonInstance) {
    return LocaleSelector.prototype._singletonInstance;
  }
  LocaleSelector.prototype._singletonInstance = this;

  let $component = $('#locale-selector-component');
  let $close = $component.find('.button-close');

  $('.region-selector').on('click', () => {
    components.open($component);
  });

  $close.on('click', () => {
    // console.log('close');
    components.close($component);
  });

  // console.log('LocaleSelector initalized!');

};

export default LocaleSelector;
