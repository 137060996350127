import {gsap} from "gsap";
import AnimatedElement from "../animated-element";

class Bulbasaur extends AnimatedElement{
  constructor(params) {
    // console.log('Bulbasaur()');
    super(params);
  }

  // OK TO OVERRIDE
  setInitialProperties () {
    // console.log('Bulbasaur.setInitialProperties()');
    gsap.set(this.selector, {opacity: 0, y: -125});
  }

  // OK TO OVERRIDE
  setAmbientAnimation () {
    // console.log('Bulbasaur.setAmbientAnimation()');
    this.ambient = gsap.timeline({
      repeat: -1,
      repeatDelay: 2 + (Math.random() * 2)
    });
    this.ambient.to(this.selector, {
      y: -35,
      rotation: -6,
      duration: 0.4,
      // delay: 0.4,
      yoyo: true,
      repeat: 1
    });
    this.ambient.to(this.selector, {
      y: -42,
      rotation: 9,
      duration: 0.4,
      yoyo: true,
      repeat: 1
    });
    this.ambient.pause();
  }

  // OK TO OVERRIDE
  setEnterAnimation() {
    // console.log('Bulbasaur.setEnterAnimation()');
    let self = this;
    this.enter = gsap.to(this.selector, {
      y: 0,
      opacity: 1,
      duration: .4,
      onComplete: function () {
        self.ambient.restart();
      }
    });
    this.enter.pause();
  }

  // OK TO OVERRIDE
  setExitAnimation() {
    // console.log('Bulbasaur.setExitAnimation()');
    let self = this;
    this.exit = gsap.to(this.selector, {
      y: -125,
      opacity: 0,
      duration: .5,
      onComplete: function () {
        self.ambient.restart();
        self.ambient.pause();
      }
    });
    this.exit.pause();
  }

}

export default Bulbasaur;
