import {gsap} from "gsap";
import AnimatedElement from "../animated-element";

class Squirtle extends AnimatedElement{
  constructor(params) {
    // console.log('Squirtle()');
    super(params);
  }

  // OK TO OVERRIDE
  setInitialProperties () {
    // console.log('Squirtle.setInitialProperties()');
    gsap.set(this.selector, {opacity: 0, x: 200});
  }

  // OK TO OVERRIDE
  setAmbientAnimation () {
    // console.log('Squirtle.setAmbientAnimation()');
    this.ambient = gsap.timeline({
      repeat: -1,
      repeatDelay: .1 + (Math.random() * 2)
    })
    this.ambient.to(this.selector, {
      y: -35,
      rotation: 6,
      duration: 0.4,
      delay: 2,
      yoyo: true,
      repeat: 1
    });
    this.ambient.to(this.selector, {
      y: -42,
      rotation: -7,
      duration: 0.4,
      yoyo: true,
      repeat: 1
    });
    this.ambient.pause();
  }

  // OK TO OVERRIDE
  setEnterAnimation() {
    // console.log('Squirtle.setEnterAnimation()');
    let self = this;
    this.enter = gsap.to(this.selector, {
      x: 0,
      opacity: 1,
      duration: .4,
      onComplete: function () {
        self.ambient.restart();
      }
    });
    this.enter.pause();
  }

  // OK TO OVERRIDE
  setExitAnimation() {
    // console.log('Squirtle.setExitAnimation()');
    let self = this;
    this.exit = gsap.to(this.selector, {
      x: 200,
      opacity: 0,
      duration: .5,
      onComplete: function () {
        self.ambient.restart();
        self.ambient.pause();
      }
    });
    this.exit.pause();
  }

}

export default Squirtle;
