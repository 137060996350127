import {gsap} from "gsap";
import AnimatedElement from "../animated-element";

class Snorlax extends AnimatedElement{
  constructor(params) {
    // console.log('Snorlax()');
    super(params);
  }

  // OK TO OVERRIDE
  setInitialProperties () {
    // console.log('Snorlax.setInitialProperties()');
    gsap.set(this.selector, {rotation: 0, opacity: 0, y: 125});
  }

  // OK TO OVERRIDE
  setAmbientAnimation () {
    // console.log('Snorlax.setAmbientAnimation()');
    this.ambient = gsap.timeline({
      repeat: -1,
      repeatDelay: .5 + (Math.random() * 2)
    });
    this.ambient.to(this.selector, {
      y: -15,
      rotation: -3,
      duration: 0.4,
      // delay: 0.4,
      yoyo: true,
      repeat: 1
    });
    this.ambient.to(this.selector, {
      y: -22,
      rotation: 4,
      duration: 0.4,
      yoyo: true,
      repeat: 1
    });

    // this.ambient.to(this.selector, {
    //   // y: -5,
    //   rotation: -3,
    //   duration: 2.4,
    //   delay: 0.4,
    //   ease: "elastic.out(1, 0.25)"
    // });
    // this.ambient.to(this.selector, {
    //   // y: -5,
    //   rotation: 2,
    //   duration: 2.4,
    //   ease: "elastic.out(1, 0.25)"
    // });

    // this.ambient.to(this.selector, {
    //   y: -5,
    //   rotation: -6,
    //   duration: .6,
    //   ease: "sine.inOut"
    // });
    // this.ambient.to(this.selector, {
    //   y: 0,
    //   rotation: 2,
    //   duration: .5,
    //   ease: "sine.inOut"
    // });
    // this.ambient.to(this.selector, {
    //   y: -5,
    //   rotation: 4,
    //   duration: .4,
    //   ease: "sine.inOut"
    // });
    // this.ambient.to(this.selector, {
    //   y: 0,
    //   rotation: -3,
    //   duration: .3,
    //   ease: "sine.inOut"
    // });
    this.ambient.pause();
  }

  // OK TO OVERRIDE
  setEnterAnimation() {
    // console.log('Snorlax.setEnterAnimation()');
    let self = this;
    this.enter = gsap.to(this.selector, {
      y: 0,
      opacity: 1,
      duration: .4,
      onComplete: function () {
        self.ambient.restart();
      }
    });
    this.enter.pause();
  }

  // OK TO OVERRIDE
  setExitAnimation() {
    // console.log('Snorlax.setExitAnimation()');
    let self = this;
    this.exit = gsap.to(this.selector, {
      y: 125,
      opacity: 0,
      duration: .5,
      onComplete: function () {
        self.ambient.restart();
        self.ambient.pause();
      }
    });
    this.exit.pause();
  }

}

export default Snorlax;
