import {gsap} from "gsap";
import AnimatedElement from "../animated-element";
import { getWidth } from "../../utilities";

class Pikachu extends AnimatedElement{
  constructor(params) {
    // console.log('Pikachu()');
    super(params);
  }

  // OK TO OVERRIDE
  setInitialProperties () {
    // console.log('Pikachu.setInitialProperties()');
    gsap.set(this.selector, {opacity: 0, y: -150});
  }

  // OK TO OVERRIDE
  setAmbientAnimation () {
    // console.log('Pikachu.setAmbientAnimation()');

    this.ambient = gsap.timeline({
      repeat: -1,
      repeatDelay: 0
    })
    this.ambient.to(this.selector, {
      y: -25,
      rotation: 12,
      duration: 0.4,
      // delay: 0.4,
      yoyo: true,
      repeat: 1
    });
    this.ambient.to(this.selector, {
      y: -40,
      rotation: -3,
      duration: 0.4,
      yoyo: true,
      repeat: 1
    });
    this.ambient.to(this.selector, {
      y: -5,
      rotation: 2,
      duration: 1.2,
      yoyo: true,
      repeat: 1
    });
    this.ambient.to(this.selector, {
      y: -3,
      rotation: -2,
      duration: 1,
      yoyo: true,
      repeat: 1
    });
    this.ambient.pause();
  }

  // OK TO OVERRIDE
  setEnterAnimation() {
    // console.log('Pikachu.setEnterAnimation()');
    let self = this;
    let ease = "elastic.out(1, 0.5)";
    let duration = 3.5;
    if (getWidth() < 768) {
      ease = "sine.in";
      duration = 0.5;
    }
    this.enter = gsap.to(this.selector, {
      y: 0,
      opacity: 1,
      duration: duration,
      ease: ease,
      onComplete: function () {
        self.ambient.restart();
      }
    });
    this.enter.pause();
  }

  // OK TO OVERRIDE
  setExitAnimation() {
    // console.log('Pikachu.setExitAnimation()');
    let self = this;
    this.exit = gsap.to(this.selector, {
      y: -200,
      opacity: 0,
      duration: .5,
      onComplete: function () {
        self.ambient.restart();
        self.ambient.pause();
      }
    });
    this.exit.pause();
  }

}

export default Pikachu;