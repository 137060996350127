import VideoPlayer from "./components/video";
import LeavingSite from "./components/leaving-site";
import LocaleSelector from "./components/locale-selector";
import IsVisible from "./components/is-visible";
// import smoothscroll from "./components/smoothscroll";
// import ScrollArrow from "./components/scroll-arrow";
import Home from "./home";
import {gsap} from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";

// console.log('NOT READY');
$(function(){
  // console.log('READY');
  $(".pokemon-gus-container").GlobalUtilityStrip();

  //register the plugin (just once)
  gsap.registerPlugin(MotionPathPlugin);

  // initialize the smooth scrolling polyfill
  // smoothscroll.polyfill();

  document.body.classList.add('dom-ready');

  window.trailer = new VideoPlayer();
  new LeavingSite();
  new LocaleSelector();
  new IsVisible();
  // new ScrollArrow();

  let page = document.body.getAttribute('data-page');
  // let desktopNav = document.getElementById('desktop-nav');
  // if (page === 'article') {
  //   desktopNav.querySelector('.nav-news').classList.add('is-active');
  // } else {
  //   desktopNav.querySelector(`.nav-${page}`).classList.add('is-active');
  // }

  switch (page) {
    case 'home':
      let home = new Home();
      break;
  }

});
