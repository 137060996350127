// import Hero from './hero';
import { gsap } from "gsap";
import BubbleDivider from "../components/bubble-divider";
import Charmander from "../components/characters/charmander";
import Squirtle from "../components/characters/squirtle";
import Pikachu from "../components/characters/pikachu";
import Eevee from "../components/characters/eevee";
import MusicNote from "../components/music-note";
import Bulbasaur from "../components/characters/bulbasaur";
import Snorlax from "../components/characters/snorlax";
import ButtonBar from "../components/button-bar";
import CharacterCarousel from "../components/character-carousel";
import PhoneCarousel from "../components/phone-carousel";

class Home {
  constructor() {
    let heroCarousel = new PhoneCarousel({
      selector: '#phone-carousel'
    });

    let heroBubbles = new BubbleDivider({
      selector: '#hero-bubbles'
    });
    let heroPikachu = new Pikachu({
      selector: '#hero-pikachu',
      marker: '#hero-pikachu-marker'
    });

    let introBubbles = new BubbleDivider({
      selector: '#intro-bubbles'
    });

    let desktopStoreButtons = new ButtonBar({
      selector: '#desktop-store-buttons',
      marker: '#desktop-store-buttons'
    });

    let mobileStoreButtons = new ButtonBar({
      selector: '#mobile-store-buttons',
      marker: '#mobile-store-buttons'
    });

    let outroStoreButtons = new ButtonBar({
      selector: '#outro-store-buttons',
      marker: '#outro-store-buttons'
    });

    let introCharmander = new Charmander({
      selector: '#intro-charmander',
      marker: '#intro-charmander-marker'
    });
    let introSquirtle = new Squirtle({
      selector: '#intro-squirtle',
      marker: '#intro-squirtle-marker'
    });

    let gameplayEevee = new Eevee({
      selector: '#gameplay-eevee',
      marker: '#gameplay-eevee-marker'
    });

    let featuresBulbasaur = new Bulbasaur({
      selector: '#features-bulbasaur',
      marker: '#features-bulbasaur-marker'
    });
    let featuresSnorlax = new Snorlax({
      selector: '#features-snorlax',
      marker: '#features-snorlax-marker'
    });

    let phoneNote1 = new MusicNote({
      selector: '#phone-note-1',
      marker: '#phone-note-1',
      direction: 1
    });
    let phoneNote2 = new MusicNote({
      selector: '#phone-note-2',
      marker: '#phone-note-2',
      direction: 1
    });
    let phoneNote3 = new MusicNote({
      selector: '#phone-note-3',
      marker: '#phone-note-3',
      direction: 1
    });
    let phoneEars = new MusicNote({
      selector: '#phone-ears',
      marker: '#phone-ears',
      direction: 1
    });

    let pikachuNote1 = new MusicNote({
      selector: '#pikachu-note-1',
      marker: '#pikachu-note-1',
      direction: -1
    });
    let pikachuNote2 = new MusicNote({
      selector: '#pikachu-note-2',
      marker: '#pikachu-note-2',
      direction: -1
    });
    let pikachuNote3 = new MusicNote({
      selector: '#pikachu-note-3',
      marker: '#pikachu-note-3',
      direction: -1
    });


    let heroFloat = gsap.timeline({
      repeat: -1,
      repeatDelay: 0
    });
    heroFloat.to('#hero-float', {
      y: -5,
      rotation: 0.6,
      duration: 1.4,
      yoyo: true,
      repeat: 1
    });
    heroFloat.to('#hero-float', {
      y: -3,
      rotation: -0.4,
      duration: 1.3,
      yoyo: true,
      repeat: 1
    });


    let characterCarousel = new CharacterCarousel({
      selector: '#character-carousel'
    });
  }
}

export default Home;
